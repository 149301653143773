import { ref, Ref, watch } from '@nuxtjs/composition-api'

/**
 * Create atom representing a responsive state
 *
 * @description
 * By default, the layout should be determined by responsive CSS. When a user
 * interacts with the layout manually, this semaphore is set to false.
 */
const responsive = ref(true)

export function useResponsiveMenu(mini: Ref<boolean>): Ref<boolean> {
  watch(mini, function () {
    responsive.value = false
  })

  return responsive
}
