var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-my-2 tw-px-2 tw-space-y-1"},_vm._l((_vm.items),function(item){return _c('span',{key:item.title},[(item.to)?_c('nuxt-link',{staticClass:"nav--item tw-group",class:{
        'nav--item__mini': _vm.isMini,
        'nav--item__responsive': _vm.responsive,
        'nav--item__active': _vm.route.name == item.to.name,
      },attrs:{"to":item.to,"id":`prx-nav-item-${item.tag}`}},[_c('material-icon',{staticClass:"nav--icon",class:{
          'nav--icon__mini': _vm.isMini,
          'nav--icon__responsive': _vm.responsive,
          'nav--icon__active': _vm.route.name == item.to.name,
        },attrs:{"icon":item.icon}}),_vm._v(" "),(!_vm.isMini)?_c('span',{class:{
          'nav--title__responsive': _vm.responsive,
        }},[_vm._v("\n        "+_vm._s(item.title)+"\n      ")]):_vm._e()],1):_vm._e(),_vm._v(" "),(item.click)?_c('button',{staticClass:"nav--item",class:{
        'nav--item__mini': _vm.isMini,
        'nav--item__responsive': _vm.responsive,
      },attrs:{"id":`prx-nav-item-${item.tag}`},on:{"click":item.click}},[_c('material-icon',{staticClass:"nav--icon",class:{
          'nav--icon__mini': _vm.isMini,
          'nav--icon__responsive': _vm.responsive,
        },attrs:{"icon":item.icon}}),_vm._v(" "),(!_vm.isMini)?_c('span',{class:{
          'nav--title__responsive': _vm.responsive,
        }},[_vm._v("\n        "+_vm._s(item.title)+"\n      ")]):_vm._e()],1):(item.spacer)?_c('div',{attrs:{"tabindex":"-1"}},[_vm._v(" ")]):_vm._e()],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }