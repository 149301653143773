import Vue from 'vue'
import { Plugin } from '@nuxt/types'

interface AlertPayload {
  color?: string
  message: string
}

interface AlertOptions {
  actionText?: string
  actionFn?: Function
  expire?: number
}

export interface Alerts {
  $emit: (payload: AlertPayload) => void
  $on: (callback: Function) => void
  $off: (callback: Function) => void
  info: (message: string) => void
  success: (message: string) => void
  warning: (message: string) => void
  error: (message: string) => void
  custom: (payload: AlertPayload) => void
}

const alert = new Vue()

const alertsPlugin: Plugin = (_, inject) => {
  const alerts = {
    channel: 'alerts',

    // EventBus
    $emit(payload: AlertPayload) {
      alert.$emit(this.channel, payload)
    },
    $on(callback: () => {}) {
      alert.$on(this.channel, callback)
    },
    $off(callback: () => {}) {
      alert.$off(this.channel, callback)
    },

    // Helpers
    info(message: string) {
      this.$emit({ message, color: undefined })
    },
    success(message: string, options: AlertOptions = {}) {
      this.$emit({ message, color: 'success', ...options })
    },
    warning(message: string, options: AlertOptions = {}) {
      this.$emit({ message, color: 'warning', ...options })
    },
    error(message: string, options: AlertOptions = {}) {
      this.$emit({ message, color: 'error', ...options })
    },
    custom(color: string, message: string, options: AlertOptions = {}) {
      this.$emit({ message, color, ...options })
    },
  }

  inject('alerts', alerts)
}

export default alertsPlugin
