import { stringify } from 'qs'

const getDefaultState = () => {
  return {
    items: {},
  }
}

export const state = getDefaultState

export const mutations = {
  /** Set item */
  setItem(state, value) {
    const newItem = { [value.prescription]: value }
    state.items = {
      ...state.items,
      ...newItem,
    }
  },
  /** Reset state to default */
  reset(state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(state, getDefaultState())
  },
}

export const actions = {
  /** Fetch all favorites and update store */
  async fetch({ commit }, options = { limit: 0, offset: 0 }) {
    const limit = options?.limit || 0
    const offset = options?.offset || 0
    try {
      const { data } = await this.$axios.get('/api/v2/favourite/', {
        paramsSerializer: (params) => stringify(params),
        params: { limit, offset },
      })
      const items = data?.results ?? []
      items.forEach((item) => {
        commit('setItem', item)
      })
    } catch (err) {
      console.error(err)
    }
  },

  /** Creates a new favorite and updates store */
  async create({ commit }, payload) {
    const response = await this.$axios.post('/api/v2/favourite/', payload)
    commit('setItem', response.data)
    return response
  },
}
