
import { computed, defineComponent } from '@nuxtjs/composition-api'
import { mdiChevronLeft } from '@mdi/js'
import { useNavItems } from '~/lib/use-nav-items'
import whiteLabel from '~/mixins/white-label'
import { useMiniMenu } from '~/lib/use-mini-menu'
import { useResponsiveMenu } from '~/lib/use-responsive-menu'

export default defineComponent({
  mixins: [whiteLabel],

  setup() {
    // Nav menu items
    const navmenu = useNavItems()

    // Reactive mini sidebar atom
    const mini = useMiniMenu()

    // Whether to use the responsive menu or not
    const responsive = useResponsiveMenu(mini)

    // Enable mini mode when responsive mode is disabled
    const isMini = computed(
      () => mini.value === true && responsive.value === false
    )

    // Icon used by navmenu
    const chevron = mdiChevronLeft

    return { responsive, mini, isMini, navmenu, chevron }
  },
})
