import map from 'lodash/fp/map'
import reduce from 'lodash/fp/reduce'
import flow from 'lodash/fp/flow'
import first from 'lodash/fp/first'
import identity from 'lodash/fp/identity'

/**
 * Extracts clinics from /api/v2/locations/ response
 * @param {Object} locations - Locations API response
 * @returns {Object}
 */
export function extractClinics(locations) {
  return reduce((current, location) => {
    if (location.hospital.id) {
      const formattedClinics = reduce((currentClinics, clinic) => {
        const hospitalId = location.hospital.id
        const backupIdName = `_${hospitalId}`
        const formattedClinic = clinic.id
          ? clinic
          : { ...clinic, id: backupIdName }
        return {
          [clinic.id ?? backupIdName]: {
            ...formattedClinic,
            hospitalId,
          },
          ...currentClinics,
        }
      }, {})(location.clinics)
      return Object.assign(current, formattedClinics)
    }
    return current
  }, {})(locations)
}

/**
 * Gets the default clinic from all clinics
 * @param {Object[]} clinics - List of all clinics
 * @returns {Object}
 */
export function getDefaultClinic(clinics = {}) {
  return flow(map(identity), first)(clinics)
}
