export const state = () => ({
  email: '',
})

export const mutations = {
  /** Set global email */
  setEmail(state, email = '') {
    state.email = email
  },
}
