import { defineNuxtPlugin } from '@nuxtjs/composition-api'
import { extend, localize } from 'vee-validate-3'
import { required, min } from 'vee-validate-3/dist/rules'
import es from 'vee-validate-3/dist/locale/es.json'
import en from 'vee-validate-3/dist/locale/en.json'

export default defineNuxtPlugin((context) => {
  // Install English and Spanish locales.
  localize({ en, es })

  // Initialize current locale
  localize(context.i18n.locale)

  // Update when i18n locale changes
  context.app.i18n.onBeforeLanguageSwitch = (_, newLocale, isInitialSetup) => {
    if (!isInitialSetup) {
      // Skip initial setup
      localize(newLocale)
    }
  }

  /** Validate required values */
  extend('required', required)

  /** Validate minimum length of string */
  extend('min', min)
})
