export default function historySteps(name) {
  return [
    {
      target: '[data-v-step="new-favorite"]',
      content:
        'Con este botón puedes crear un machote con la estructura e información de esta receta.',
      params: { enableScrolling: false },
    },
    {
      target: '[data-v-step="copy-rx"]',
      content:
        'Con este botón, creas una receta nueva con los mismos medicamentos e indicaciónes de esta receta.',
      params: { enableScrolling: false },
    },
    {
      target: '[data-v-step="copy-patient"]',
      content:
        'Con este, creas una receta nueva para el mismo paciente, pero con los medicamentos en blanco.',
      params: { enableScrolling: false },
    },
    {
      target: '[data-v-step="copy-all"]',
      content:
        'Con este copias la receta completa: con el paciente y todos sus medicamentos, indicaciones y diagnostico.',
      params: { enableScrolling: false },
    },
    {
      target: '[data-v-step="print"]',
      content:
        'Con este botón descargas una copia de la receta en PDF, que puedes imprimir y firmar en caso de ser necesario.',
      params: { enableScrolling: false },
    },
    {
      target: '[data-v-step="cancel-rx"]',
      content:
        'Con este botón puedes cancelar la receta, de esta manera las farmacias aliadas no podrán surtirla.',
      params: { enableScrolling: false },
    },
    {
      target: '[data-v-step="resend-rx"]',
      content:
        'Con este botón puedes reenviar la receta, en caso que el email no haya llegado al paciente.',
      params: { enableScrolling: false },
    },
  ]
}
