export const state = () => ({
  feed: null,
})

export const mutations = {
  /** Set news from response value */
  setRawNews(state, value = null) {
    state.feed = value
  },
}

export const actions = {
  async fetchNews({ commit }) {
    const callback_data = {
      items: [
        {
          id: '123',
          image:
            'https://user-images.githubusercontent.com/1806894/212398624-332dbb1b-f18c-491d-93ef-74f6acdb146b.jpeg',
          url: 'https://prescrypto.github.io/maintenance/',
          title: 'Prescrypto v3.0',
          date_published: '2022-10-22T00:00:00',
          summary: 'Nuevas noticias de prescrypto',
        },
        {
          id: '124',
          image:
            'https://user-images.githubusercontent.com/1806894/212398624-332dbb1b-f18c-491d-93ef-74f6acdb146b.jpeg',
          url: 'https://prescrypto.github.io/maintenance/',
          title: 'Prescrypto y la Asociación Mexicana de Obesidad (AMO)',
          date_published: '2022-09-15T00:00:00',
          summary: 'Nuevas noticias de prescrypto',
        },
        {
          id: '125',
          image:
            'https://user-images.githubusercontent.com/1806894/212398624-332dbb1b-f18c-491d-93ef-74f6acdb146b.jpeg',
          url: 'https://prescrypto.github.io/maintenance/',
          title: 'Atrae pacientes nuevos y llena tu consultorio en 5 pasos',
          date_published: '2022-09-06T00:00:00',
          summary: 'Nuevas noticias de prescrypto',
        },
        {
          id: '126',
          image:
            'https://user-images.githubusercontent.com/1806894/212398624-332dbb1b-f18c-491d-93ef-74f6acdb146b.jpeg',
          url: 'https://prescrypto.github.io/maintenance/',
          title:
            'Prescrypto cumple con la modificación a la Ley General de Salud (30/03/2022)',
          date_published: '2022-04-05T00:00:00',
          summary: 'Nuevas noticias de prescrypto',
        },
        {
          id: '127',
          image:
            'https://user-images.githubusercontent.com/1806894/212398624-332dbb1b-f18c-491d-93ef-74f6acdb146b.jpeg',
          url: 'https://prescrypto.github.io/maintenance/',
          title:
            'Prescrypto avalado por la Asociación Psiquiátrica Mexicana (APM)',
          date_published: '2021-12-05T00:00:00',
          summary: 'Nuevas noticias de prescrypto',
        },
      ],
    }
    try {
      const { data } = await this.$local.get('/api/v2/news')
      if (!data) {
        console.log('Enter to no data condition')
        return commit('setRawNews', callback_data)
      }
      return commit('setRawNews', data)
    } catch (err) {
      console.error(err)
      console.log('Enter to set data callback on fetch error')
      return commit('setRawNews', callback_data)
    }
  },
}
