import { stringify } from 'qs'
import axios from 'axios'

export default function ({ $config }, inject) {
  /** Create a custom axios instance for the local API */
  const local = axios.create({
    port: $config.port,
    paramsSerializer: (params) => stringify(params),
  })
  inject('local', local)
}
