
import { computed, defineComponent } from '@nuxtjs/composition-api'
import { useMiniMenu } from '~/lib/use-mini-menu'
import { useResponsiveMenu } from '~/lib/use-responsive-menu'

export default defineComponent({
  setup() {
    // Reactive mini sidebar atom
    const mini = useMiniMenu()

    // Whether to use the responsive menu or not
    const responsive = useResponsiveMenu(mini)

    // Enable mini mode when responsive mode is disabled
    const isMini = computed(
      () => mini.value === true && responsive.value === false
    )

    return { responsive, isMini }
  },
})
