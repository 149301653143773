import money from '@/lib/money'

// TODO: Read optional environment variable
export const minAmount = '20000'
export const maxAmount = '999999'

/**
 * Checks if input is in a valid monetary format with the desired prefix
 * @param {string} value - The input value
 * @returns {boolean}
 */
export function isMoney(value = '') {
  const prefix = money.prefix.replace('$', '\\$')
  const regex = `^${prefix}[+-]?[0-9]{1,3}(?:,?[0-9]{3})*.[0-9]{2}$`
  const pattern = new RegExp(regex)
  return pattern.test(value)
}

/**
 * Checks if input is not the default 0.00
 * @description This is needed because the input is prefixed with a currency symbol
 * @param {string} value - The input value
 * @returns {boolean}
 */
export function isMoneyDefault(value = '') {
  return value === `${money.prefix}0.00`
}

/** Convert money string to number */
function moneyToNumber(value) {
  const stringAmount = value.split(money.prefix)[1].replace(',', '')
  return stringAmount * 100
}

/**
 * Checks if input is within the limit
 * @param {string} value - The input value
 * @returns {boolean}
 */
export function isMoneyBelowLimit(value = '', limit = maxAmount) {
  return moneyToNumber(value) <= limit
}

/**
 * Checks if input is above the minimum
 * @param {string} value - The input value
 * @returns {boolean}
 */
export function isMoneyAboveMinimum(value = '', min = minAmount) {
  return moneyToNumber(value) >= min
}
