import { v4 as uuidv4 } from 'uuid'
import Vue from 'vue'
import toSafeInteger from 'lodash/fp/toSafeInteger'
import isString from 'lodash/fp/isString'
import toLower from 'lodash/fp/toLower'

const getDefaultState = () => {
  return {
    patient: null,
    medications: [],
    keywords: [],
    clinic: null,
    hospital: null,
    RxWarning: {
      send: false,
      clinic_id: null,
    },
  }
}

export const state = getDefaultState

export const mutations = {
  /** Reset active patient */
  resetPatient(state) {
    state.patient = null
  },

  /**
   * Set active patient
   * @param {import('../lib/format-rx-request').Rx} state - Rx store shape
   * @param {import('../lib/format-rx-request').Patient} patient - A patient
   */
  setPatient(state, patient) {
    if (patient) {
      state.patient = Object.assign({}, state.patient, patient)
    } else {
      state.patient = null
    }
  },

  /** Set active clinic */
  setClinic(state, clinic) {
    state.clinic = clinic
  },

  /** Set active hospital */
  setHospital(state, hospital) {
    hospital = toSafeInteger(hospital)
    state.hospital = hospital === 0 ? null : hospital
  },

  /** Set Rx Warning config
  Keep safe in state metadata about the warning
  RxWarning : {
    sendRxWarning: Boolean | To know if show or not the warning
    clinic_id: String | Metadata to know what clinic compare
  }
  */
  setRxWarning(state, rx_warning) {
    if (isString(rx_warning)) {
      state.RxWarning = {
        send: toLower(rx_warning) === 'true',
        clinic_id: state.clinic,
      }
    }
  },

  /**
   * Add a keyword session ID to the list
   * @param {import('../lib/format-rx-request').Rx} state - Rx store shape
   * @param {String} sessionId - A keyword session ID
   */
  addKeywordSessionId(state, sessionId) {
    state.keywords.push(sessionId)
  },

  /**
   * Remove a keyword session ID from the list
   * @param {import('../lib/format-rx-request').Rx} state - Rx store shape
   * @param {String} sessionId - A keyword session ID
   */
  removeKeywordSessionId(state, sessionId) {
    const index = state.keywords.indexOf(sessionId)
    if (index > -1) {
      state.keywords.splice(index, 1)
    }
  },

  /**
   * Reset the list of keyword session IDs
   * @param {import('../lib/format-rx-request').Rx} state - Rx store shape
   */
  resetKeywordSessionIds(state) {
    state.keywords = []
  },

  /**
   * Set all medications in one go, used by drag & drop implementation
   * @param {import('../lib/format-rx-request').Rx} state - Rx store shape
   * @param {Object} medications - Mutation payload with medication rows
   */
  setMedicationRows(state, medications) {
    Vue.set(state, 'medications', medications)
  },

  /**
   * Add new medication row
   * @param {import('../lib/format-rx-request').Rx} state - Rx store shape
   */
  addMedicationRow(state, medication = {}) {
    const fallback = {
      presentation: null,
      instructions: null,
      drug: null, //  TODO: Remove drug field from medications
      drug_upc: null,
      order_id: '0',
      ad_id: null,
      category: 'standard_drug',
      qty: 1000,
      keyword: '',
      via: null,
      key: uuidv4(),
    }
    state.medications.push({ ...fallback, ...medication })
  },

  /**
   * Remove medication row
   * @param {import('../lib/format-rx-request').Rx} state - Rx store shape
   * @param {number} index - Index of medication in array
   */
  removeMedicationRow(state, index) {
    Vue.delete(state.medications, index)
  },

  /**
   * Update medication row
   * @param {import('../lib/format-rx-request').Rx} state - Rx store shape
   * @param {Object} payload - Mutation payload with medication object and index
   */
  updateMedicationRow(state, payload) {
    const { index, medication } = payload

    Vue.set(state.medications, index, {
      ...state.medications[index],
      presentation: medication.presentation,
      drug: null,
      drug_upc: medication.uniqueId,
      order_id: medication.orderId,
      ad_id: medication.adId,
      category: medication.category,
      knaou_price: medication.priceKnaou2562019,
    })
  },

  /**
   * Set via of medication
   * @param {import('../lib/format-rx-request').Rx} state - Rx store shape
   * @param {Object} payload - Mutation payload with quantity and index
   */
  setMedicationVia(state, payload) {
    const { index, via } = payload
    Vue.set(state.medications[index], 'via', via)
  },

  /**
   * Set quantity of medication
   * @param {import('../lib/format-rx-request').Rx} state - Rx store shape
   * @param {Object} payload - Mutation payload with quantity and index
   */
  setMedicationQuantity(state, payload) {
    const { index, quantity } = payload
    Vue.set(state.medications[index], 'qty', quantity)
  },

  /**
   * Set indication of medication
   * @param {import('../lib/format-rx-request').Rx} state - Rx store shape
   * @param {Object} payload - Mutation payload with indication and index
   */
  setIndication(state, payload) {
    const { index, indication } = payload
    Vue.set(state.medications[index], 'instructions', indication)
  },

  /**
   * Set Search keyword to the medication
   * @param {import('../lib/format-rx-request').Rx} state - Rx store shape
   * @param {Object} payload - Mutation payload with quantity and index
   */
  setMedicationKeywords(state, payload) {
    const { index, keyword } = payload
    Vue.set(state.medications[index], 'keyword', keyword)
  },

  /** Reset medications to base state */
  resetMedications(state) {
    state.medications = []
  },

  /** Reset state to default */
  reset(state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(state, getDefaultState())
  },
}
