import compact from 'lodash/fp/compact'
import {
  mdiFacebook,
  mdiTwitter,
  mdiYoutube,
  mdiInstagram,
  mdiLinkedin,
} from '@mdi/js'

export default {
  data() {
    const { meta } = this.$whiteLabel?.options
    const { name } = meta
    const pathName = name.toLowerCase()

    return {
      appName: name,
      rxFooter: `/${pathName}/rx_footer.png`,
      // Social sharing URLs and icons
      socialLinks: compact([
        meta.social?.facebook && {
          url: meta.social?.facebook,
          icon: mdiFacebook,
        },
        meta.social?.twitter && {
          url: `https://twitter.com/${meta.social.twitter}`,
          icon: mdiTwitter,
        },
        meta.social?.youtube && {
          url: meta.social?.youtube,
          icon: mdiYoutube,
        },
        meta.social?.instagram && {
          url: meta.social?.instagram,
          icon: mdiInstagram,
        },
        meta.social?.linkedin && {
          url: meta.social?.linkedin,
          icon: mdiLinkedin,
        },
      ]),
    }
  },

  computed: {
    // Whether dark mode is enabled or not
    dark() {
      return this.$vuetify.theme.dark
    },
    // Light or dark mode app logo
    appLogo() {
      const { name } = this.$whiteLabel?.options.meta
      const pathName = name.toLowerCase()
      return `/${pathName}/${this.dark ? 'logo_dark' : 'logo'}.png`
    },
    // Light or dark mode small app logo
    appLogoSmall() {
      const { name } = this.$whiteLabel?.options.meta
      const pathName = name.toLowerCase()
      return `/${pathName}/${this.dark ? 'logo-small_dark' : 'logo-small'}.png`
    },
  },
}
