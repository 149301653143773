const getDefaultState = () => {
  return {
    displayTourOnce: true,
  }
}

export const state = getDefaultState

export const mutations = {
  /** Set show tour once true */
  setDisplayTourOnce(state, value) {
    state.displayTourOnce = value
  },
  /** Reset state to default */
  reset(state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(state, getDefaultState())
  },
}
