import { stringify } from 'qs'
import reduce from 'lodash/fp/reduce'

const getDefaultState = () => {
  return {
    count: -1,
    items: {},
  }
}

export const state = getDefaultState

export const mutations = {
  /** Set count from API response */
  setCount(state, value) {
    if (value === null || value === 'undefined') {
      throw new Error('Value required for count')
    }
    state.count = value
  },

  /** Set items from API response */
  setItems(state, value = []) {
    state.items = reduce((items, item) => {
      items[item.signature] = item
      return items
    }, state.items)(value)
  },

  /** Set item in store directly */
  setItem(state, value) {
    state.items[value.signature] = value
  },

  /** Reset state to default */
  reset(state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(state, getDefaultState())
  },
}

export const actions = {
  /** Fetch all rx and update store */
  async fetch(
    { commit },
    { limit = 0, offset = 0, search = '', external_patient_file = '' },
  ) {
    try {
      const response = await this.$axios.get('/api/v2/rx-endpoint/', {
        paramsSerializer: (params) => stringify(params),
        params: { limit, offset, search, external_patient_file },
      })
      commit('setCount', response.data.count)
      commit('setItems', response.data.results)
      return response
    } catch (err) {
      console.error(err)
    }
  },

  async fetchOne({ commit }, signature) {
    if (!signature) {
      throw new Error('signature is required to fetch rx')
    }

    try {
      const response = await this.$axios.get(`/api/v2/rx-endpoint/${signature}`)
      commit('setItem', response.data)
      return response
    } catch (err) {
      console.error(err)
    }
  },
}
