var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('nuxt-link',{staticClass:"cta--wrapper",class:{
    'cta--wrapper__mini': _vm.isMini,
    'cta--wrapper__responsive': _vm.responsive,
  },attrs:{"to":"/prescriptions/new","tabindex":"-1","id":"prx-nav-new_rx"}},[_c('button',{staticClass:"cta",class:{
      cta__mini: _vm.isMini,
      cta__responsive: _vm.responsive,
      cta__disabled: !_vm.verified,
    },attrs:{"type":"button","tabindex":_vm.verified ? 0 : -1}},[(_vm.responsive)?_c('material-icon',{staticClass:"cta--icon cta--icon__responsive",attrs:{"icon":_vm.newRx}}):_c('material-icon',{staticClass:"cta--icon",class:{
        'tw--ml-1 tw-mr-3': !_vm.isMini,
      },attrs:{"icon":_vm.newRx}}),_vm._v(" "),(_vm.responsive)?_c('span',{staticClass:"tw-block md:tw-hidden lg:tw-block"},[_vm._v("Receta Nueva")]):_c('span',{class:{ 'tw-hidden': _vm.isMini }},[_vm._v("Receta Nueva")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }