import Vue from 'vue'
import VueTour from 'vue-tour'
import 'vue-tour/dist/vue-tour.css'
import rxSteps from './tour/rx-steps'
import historySteps from './tour/history-steps'

Vue.use(VueTour)

export default function (context, inject) {
  const name = context.$config.whiteLabel
  inject('tour', {
    rx: rxSteps(name, context),
    history: historySteps(name),
  })
}
