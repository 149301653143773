
import {
  mdiAccount,
  mdiShieldAccount,
  mdiAccountCancel,
  mdiAccountKey,
  mdiHospitalBuilding,
  mdiMortarPestlePlus,
  mdiArrowLeft,
  mdiLogout,
  mdiTuneVerticalVariant,
} from '@mdi/js'
import Layout from '@/components/Layout'
import session from '@/mixins/session'
import prx from '@/mixins/prx'
import darkMode from '@/mixins/dark-mode'

export default {
  components: { Layout },

  mixins: [prx, session, darkMode],

  data() {
    return {
      exitIcon: mdiArrowLeft,
      logoutIcon: mdiLogout,
      // Side navigation menu
      tabs: [
        {
          name: 'Mi Cuenta',
          icon: mdiAccount,
          title: 'Completa tus datos',
          to: '/settings/user',
        },
        {
          name: 'Opciones de Perfil',
          icon: mdiTuneVerticalVariant,
          title: 'Opciones de interfaz y del perfil',
          to: '/settings/options',
        },
        {
          name: 'Contraseña y Seguridad',
          title: 'Change password',
          icon: mdiShieldAccount,
          to: '/settings/security',
        },
        {
          name: 'Cerrar Cuenta',
          icon: mdiAccountCancel,
          to: '/settings/close-account',
        },
        {
          name: 'Exportar Llaves',
          icon: mdiAccountKey,
          to: '/settings/export',
        },
        {
          name: 'Administra Sitios de Trabajo',
          icon: mdiHospitalBuilding,
          to: '/settings/hospitals',
        },
        {
          name: 'Farmacias',
          icon: mdiMortarPestlePlus,
          to: '/settings/pharmacies',
        },
      ],
    }
  },

  computed: {
    // Current user
    user() {
      return this.$auth?.user
    },
    hasFillupData() {
      return Boolean(this.$auth.$state?.user?.has_fillup_data)
    },
    hasHospitalData() {
      return Boolean(this.$auth.$state?.user?.has_hospital_data)
    },
  },

  methods: {
    /** Returns to page user came from when entering settings */
    returnToOrigin() {
      const { origin } = this.$store.state.ui
      if (origin) {
        this.$router.push(origin)
      } else {
        this.$router.push({ name: 'index' })
      }
      // Close drawer on mobile
      this.$store.commit('ui/setDrawer', false)
    },
  },
}
