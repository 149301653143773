export default function rxSteps(name, context) {
  return [
    /**
      Welcome tab,  list tag: '[data-v-step="fav"]', list index: 0
    **/
    {
      target: '[data-v-step="fav"]',
      content: `Bienvenido a ${name}, </br>
    <strong>Sigue este tutorial para hacer tu primera receta</strong>`,
    },
    {
      target: '[data-v-step="fav"]',
      content: `<ol class="text-left">
<li class="tw-mb-4">Funcionalidades importantes y novedades 📢:</li>

<li class="tw-mb-2">Tus pacientes ya pueden buscar su farmacia más cercana en Farmacias del Ahorro, Benavides, Walmart Express y Farmacias Guadalajara 🔍📍💊</li>

<li class="tw-mb-2">¡Prueba nuestra funcionalidad de interacciones medicamentosas! 🚫💊🤝</li>

<li>Formato de receta nuevo, ¡revisa tu configuración para activarlo! 🆕📜⚙️ </li>
</ol>`,
    },
    {
      target: '[data-v-step="help"]',
      content:
        'Si necesita ayuda o tiene algún comentario, nos lo puede dejar aquí',
    },
    /**
  // TODO add this step once can be show it without UI problems see
  // https://github.com/Prescrypto/prescriptwo/pull/299#issuecomment-722585133
  {
    target: '[data-v-step="clinic"]',
    content: 'Elige el centro de trabajo desde el cual emites la receta'
  },
  **/
    {
      target: '[data-v-step="patient"]',
      content: `Crea o busca un paciente <strong>aquí</strong>☝!, al seleccionarlo o crearlo puedes editarlo también`,
    },
    {
      target: '[data-v-step="11"]',
      content: 'Agrega un diagnóstico al paciente',
    },
    {
      target: '[data-v-step="diagnosis"]',
      content:
        'Puedes elegir no mostrar el diagnóstico en la receta, para proteger la privacidad de su paciente',
    },
    {
      target: '[data-v-step="12"]',
      content: 'Las indicaciones extras son opcionales!',
      before() {
        // Close the search dropdown if he user goes back a step
        context.$bus.$emit('rx-medication-tour', false)
      },
    },
    {
      target: '[data-v-step="13"]',
      params: { placement: 'top' },
      content: `Puedes buscar y seleccionar medicamentos de \
              manera fácil aquí, al ser seleccionados puedes ver detalles de cada medicamento \
              puedes indicar la cantidad de medicamentos a recetar, o dejar que el farmacéutico realice el cálculo con la posología`,
      before() {
        // Open the search dropdown
        context.$bus.$emit('rx-medication-tour', true)
      },
    },
    {
      target: '[data-v-step="14"]',
      placement: 'bottom',
      content: 'Puedes agregar más medicamentos desde aquí',
      before() {
        // Close the search dropdown if he user goes back a step
        context.$bus.$emit('rx-interactions-tour', false)

        // Set medication rows
        context.store.commit('rx/setMedicationRows', [
          {
            drug_upc: '7501125116810',
            instructions: 'Tomar 2 pastillas cada 8 horas. (TEST)',
            order_id: 1,
            presentation: 'Paracetamol',
            qty: 1,
            qty_label: '1',
          },
          {
            drug_upc:
              '7ab558c6f78e3f52d1235facaddd07e6e71bb8329e12cff06f2c5500304ae312',
            instructions: 'Tomar 2 pastillas cada 8 horas. (TEST)',
            order_id: 1,
            presentation: 'Warfarina',
            qty: 1,
            qty_label: '1',
          },
        ])
      },
    },
    {
      target: '[data-v-step="interactions"]',
      content:
        'Puedes revisar interacciones medicamentosas con inteligencia artificial',
      placement: 'bottom',
      before() {
        // Open the interactions modal
        context.$bus.$emit('rx-interactions-tour', true)
      },
    },
    {
      target: '[data-v-step="fav"]',
      content: `Aquí puedes ver los machotes de tus recetas favoritas, <br>
    <strong>Tus recetas favoritas son machotes que llenas con un solo click! Para guardar una receta como favorita, primero la debes llenar y guardar, y después guardarla como favorito.</strong>`,
      before() {
        // Close the interactions modal
        context.$bus.$emit('rx-interactions-tour', false)
      },
    },
    {
      target: '[data-v-step="15"]',
      content: 'Finaliza tu receta firmando y enviando la receta 👌',
    },
  ]
}
