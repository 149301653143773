import yn from 'yn'

/**
 * Check if paylinks are disabled by context
 * @param {Object} context - The application context
 * @returns {boolean}
 */
export function derivePaylinksStateFromContext({ $config, $whiteLabel }) {
  const disabledByEnvironment = yn($config.widgetDisablePayment, {
    default: false,
  })

  const paylinksEnabled =
    $whiteLabel.options.widgets.payment?.enabled && !disabledByEnvironment

  return Boolean(paylinksEnabled)
}
