import { stringify } from 'qs'

export default {
  methods: {
    getMedicToken() {
      const token = this.$auth.$storage.getState('token.local')
      return token ? token.split(' ')[1] : ''
    },
    MEDIC_URL() {
      const token = this.getMedicToken()
      return `${this.$config.baseUrl}new/api_token/?token=${token}`
    },
    RX_URL(id) {
      return `${this.MEDIC_URL()}&redirect_url=/prescription/${id}`
    },
    RXLIST_URL() {
      return `${this.MEDIC_URL()}&redirect_url=/prescriptions/`
    },
    RXSEARCH_URL(search) {
      const query = stringify({ search })
      return `${this.MEDIC_URL()}&redirect_url=/prescriptions/?${query}`
    },
    RXPRINT_URL() {
      return `${this.MEDIC_URL()}&redirect_url=/print/`
    },
    RXPRINT(id) {
      return `${this.RXPRINT_URL()}${id}`
    },
    SETTINGS_URL() {
      return `${this.MEDIC_URL()}&redirect_url=/verify/`
    },
    ADD_EMAIL_URL() {
      return `${this.MEDIC_URL()}&redirect_url=/accounts/email/`
    },
    EXPORT_KEYS_URL() {
      return `${this.MEDIC_URL()}&redirect_url=/medic/keys/`
    },
    HOSPITALS_URL() {
      return `${this.MEDIC_URL()}&redirect_url=/hospitals/`
    },
    CONTACT_URL() {
      return `${this.$config.baseUrl}contact/`
    },
  },
}
