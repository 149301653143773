export default {
  computed: {
    /** Whether the white label has a redirect */
    redirect() {
      return this.$whiteLabel.options.meta.redirect
    },
    /** Whether to show logout or redirect text */
    logoutText() {
      if (this.redirect) {
        return 'Volver'
      }
      return 'Cerrar sesión'
    },
  },

  methods: {
    logout() {
      this.$router.push('/logout')
    },
  },
}
