// Get map of screen size as numeric values
const screens: { [key: string]: string } = {
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
  '2xl': '1536px',
}
const sizes: { [key: string]: number } = {}
Object.keys(screens).forEach((size) => {
  sizes[size] = Number(screens[size].split('px')[0])
})

export default sizes
