var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ValidationProvider',{attrs:{"name":_vm.name,"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function({ errors, ariaMsg, ariaInput }){return [_c('label',{staticClass:"tw-block tw-text-sm tw-font-medium tw-text-gray-700 dark:tw-text-gray-100",attrs:{"for":_vm.name}},[_vm._v("\n    "+_vm._s(_vm.label)+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"tw-mt-1 tw-relative tw-rounded-md tw-shadow-sm"},[_c('input',_vm._b({staticClass:"tw-bg-white dark:tw-bg-gray-900 tw-block tw-w-full tw-shadow-sm sm:tw-text-sm tw-border tw-border-solid tw-pr-10 tw-rounded-md form-input",class:{
        'tw-text-black dark:tw-text-white tw-border-gray-300 dark:tw-border-gray-700 focus:tw-ring-primary-500 focus:tw-border-primary-500 dark:focus:tw-ring-primary-600 dark:focus:tw-border-primary-600':
          !errors[0],
        'tw-text-red-900 tw-border-red-300 dark:tw-border-red-500 tw-placeholder-red-300 dark:tw-placeholder-red-500 focus:tw-ring-red-500 focus:tw-border-red-500':
          errors[0],
      },attrs:{"autofocus":_vm.autofocus,"type":_vm.type,"name":_vm.name,"id":_vm.name,"placeholder":_vm.placeholder,"aria-invalid":errors[0],"aria-describedby":`${_vm.name}-error`},domProps:{"value":_vm.value},on:{"input":_vm.onInput}},'input',ariaInput,false))]),_vm._v(" "),_c('p',_vm._b({staticClass:"tw-mt-1 tw-mb-1 tw-text-sm tw-text-red-600",class:{
      'tw-invisible': !errors[0],
      'tw-visible': errors[0],
    },attrs:{"id":`${_vm.name}-error`},domProps:{"textContent":_vm._s(errors[0] || _vm.$t('no_error'))}},'p',ariaMsg,false))]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }