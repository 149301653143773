import Vue from 'vue'
import {
  Validator,
  install as VeeValidateLite,
} from 'vee-validate/dist/vee-validate.minimal.esm.js'
import {
  required,
  email,
  confirmed,
  date_format,
  included,
  numeric,
  min_value,
  min,
  max,
  digits,
  alpha_spaces,
} from 'vee-validate/dist/rules.esm.js'
import es from 'vee-validate/dist/locale/es'
import isDate from '@/lib/is-date'
import isAge from '@/lib/is-age'
import {
  isMoney,
  isMoneyDefault,
  isMoneyBelowLimit,
  isMoneyAboveMinimum,
  minAmount,
  maxAmount,
} from '@/lib/is-money'

export default function ({ $axios, $local, $bus, store }) {
  Validator.extend('required', required)
  Validator.extend('email', email)
  Validator.extend('confirmed', confirmed)
  Validator.extend('date_format', date_format)
  Validator.extend('included', included)
  Validator.extend('numeric', numeric)
  Validator.extend('min_value', min_value)
  Validator.extend('min', min)
  Validator.extend('max', max)
  Validator.extend('digits', digits)
  Validator.extend('alpha_spaces', alpha_spaces)

  // Validates URL safe characters
  Validator.extend('url_safe', {
    validate(value) {
      return /^[\sa-zA-Z0-9-_]+$/.test(value)
    },
  })

  // Validates logged in user's password
  Validator.extend('valid_password', {
    async validate(value) {
      // Fail if login expired
      if (!store.$auth.loggedIn) {
        return false
      }

      // Try login with store user
      try {
        await $axios.$post('/rest-auth/login/', {
          email: store.$auth.user.email,
          password: value,
        })
        return true
      } catch (err) {
        return false
      }
    },
  })

  // Validates CLABE
  Validator.extend('clabe', {
    async validate(value) {
      // Test CLABE with local API service
      try {
        const { data } = await $local.get(`/api/v2/clabe/${value}`)
        $bus.$emit('clabe-validated', data)
        return data.ok
      } catch (err) {
        return false
      }
    },
  })

  // Use validation value from the component itself
  Validator.extend('intl_phone', {
    validate(_, args) {
      const valid = args[0] === 'true'
      const message = args[1]
      return {
        valid,
        data: message,
      }
    },
  })

  // Custom rule for supporting our API dates
  Validator.extend('date_or_age', {
    validate(value) {
      if (value) {
        return isDate(value) || isAge(value)
      }
    },
  })

  // Custom rule for failing against existing emails
  Validator.extend('existing', {
    validate(value, values = []) {
      return values.includes(value) === false
    },
  })

  // Custom rule for checking currency for paylinks
  Validator.extend('money', {
    validate(value) {
      return isMoney(value) && isMoneyDefault(value) === false
    },
  })

  // Custom rule for checking if paylink value exceeds max
  Validator.extend('max_money', {
    validate(value) {
      return isMoneyBelowLimit(value)
    },
  })

  // Custom rule for checking if paylink value is below min
  Validator.extend('min_money', {
    validate(value) {
      return isMoneyAboveMinimum(value)
    },
  })

  // Custom dictionary
  const dictionary = {
    es: {
      messages: {
        existing() {
          return 'El correo electrónico ya esta registrado'
        },
        valid_password() {
          return 'La contraseña actual no es valida'
        },
        max_money() {
          return `La cantidad excede el máximo permitida de $${maxAmount / 100}`
        },
        min_money() {
          return `La cantidad debe ser mayor o igual a $${minAmount / 100}`
        },
        intl_phone(_, __, message) {
          return message
        },
      },
    },
  }

  Validator.localize(dictionary)
  Validator.localize('es', es)

  Vue.use(VeeValidateLite, {
    locale: 'es',
    dictionary: { es },
    inject: false,
  })
}
