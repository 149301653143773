import Vue from 'vue'
import { Notifier } from '@airbrake/browser'

export default function ({ $config }) {
  // Init var to establish deployment pipeline stage/tier
  let deploy_tier = 'development'

  if ($config.isProduction) {
    deploy_tier = 'production'
  }

  if ($config.airbrakeProjectId && $config.airbrakeApiKey) {
    const airbrake = new Notifier({
      environment: deploy_tier,
      projectId: $config.airbrakeProjectId,
      projectKey: $config.airbrakeApiKey,
    })

    Vue.config.errorHandler = function (err, vm, info) {
      airbrake.notify({
        error: err,
        params: { info },
      })
    }
  }
}
