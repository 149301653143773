import variants from '@/defaults/variants'

// Choose white-label variant based on runtime config
export default function ({ $config }, inject) {
  // Inject $whiteLabel into context
  inject('whiteLabel', {
    name: $config.whiteLabel,
    options: variants[$config.whiteLabel],
  })
}
