/** This module is for global UI state */

// TODO: Try to read client hints for initial render

export const state = () => ({
  // Mobile search overlay
  mobileSearch: false,
  // Application drawer
  drawer: false,
  // Whether to always show the drawer
  permanent: false,
  // Mini sidebar
  mini: true,
  // Whether to render sidebar on right side
  right: false,
  // Whether to render the app bar
  navbar: false,
  // A route cache
  origin: null,
  // Cached window width
  width: 0,
})

export const mutations = {
  /**
   * Set mobile search modal visibility
   * @param {boolean} value - Sets visibility of modal
   */
  setMobileSearch(state, value) {
    state.mobileSearch = value
  },

  /** Show mobile search modal */
  showMobileSearch(state) {
    state.mobileSearch = true
  },

  /** Hide mobile search modal */
  hideMobileSearch(state) {
    state.mobileSearch = false
  },

  /** Toggle mobile search modal visibility */
  toggleMobileSearch(state) {
    state.mobileSearch = !state.mobileSearch
  },

  /** Set drawer from value */
  setDrawer(state, value) {
    state.drawer = value
  },

  /** Set permanent from value */
  setPermanent(state, value) {
    state.permanent = value
  },

  /** Set mini from value */
  setMini(state, value) {
    state.mini = value
  },

  /** Set right from value */
  setRight(state, value) {
    state.right = value
  },

  /** Set navbar from value */
  setNavbar(state, value) {
    state.navbar = value
  },

  /** Set route to cache */
  setOrigin(state, value) {
    if (value?.name) {
      state.origin = value
    }
  },

  /** Reset route cache */
  clearOrigin(state) {
    state.origin = null
  },

  /** Cache width in store */
  setWidth(state, value) {
    state.width = value
  },
}

export const actions = {
  /** Set UI defaults based on width */
  setDefaultsForWidth({ commit, rootState }, width) {
    const { xs, md } = rootState.thresholds

    commit('setWidth', width)
    commit('setDrawer', width > xs)
    commit('setPermanent', width > xs)
    commit('setMini', width > xs && width < md)
    commit('setRight', width <= xs)
    commit('setNavbar', width <= xs)
  },
}
