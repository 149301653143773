import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6c1ff114 = () => interopDefault(import('../pages/check-email.vue' /* webpackChunkName: "pages/check-email" */))
const _ddb0fef0 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _3e4cce0a = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _38e94a26 = () => interopDefault(import('../pages/medications/index.vue' /* webpackChunkName: "pages/medications/index" */))
const _3600643a = () => interopDefault(import('../pages/password-updated.vue' /* webpackChunkName: "pages/password-updated" */))
const _43236a76 = () => interopDefault(import('../pages/paylinks/index.vue' /* webpackChunkName: "pages/paylinks/index" */))
const _2f013e3b = () => interopDefault(import('../pages/prescriptions/index.vue' /* webpackChunkName: "pages/prescriptions/index" */))
const _fb912958 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _2ec6d173 = () => interopDefault(import('../pages/registered.vue' /* webpackChunkName: "pages/registered" */))
const _48409666 = () => interopDefault(import('../pages/resend-confirmation.vue' /* webpackChunkName: "pages/resend-confirmation" */))
const _6f87c44a = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _07db38d4 = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _dfdcea46 = () => interopDefault(import('../pages/settings/close-account.vue' /* webpackChunkName: "pages/settings/close-account" */))
const _0c62dbff = () => interopDefault(import('../pages/settings/export.vue' /* webpackChunkName: "pages/settings/export" */))
const _6b31d701 = () => interopDefault(import('../pages/settings/hospitals/index.vue' /* webpackChunkName: "pages/settings/hospitals/index" */))
const _29babe3a = () => interopDefault(import('../pages/settings/options.vue' /* webpackChunkName: "pages/settings/options" */))
const _301bb4dc = () => interopDefault(import('../pages/settings/pharmacies.vue' /* webpackChunkName: "pages/settings/pharmacies" */))
const _215278cb = () => interopDefault(import('../pages/settings/security.vue' /* webpackChunkName: "pages/settings/security" */))
const _6b1f2156 = () => interopDefault(import('../pages/settings/user.vue' /* webpackChunkName: "pages/settings/user" */))
const _715d0ae2 = () => interopDefault(import('../pages/settings/hospitals/new.vue' /* webpackChunkName: "pages/settings/hospitals/new" */))
const _9154ed28 = () => interopDefault(import('../pages/settings/hospitals/_id/index.vue' /* webpackChunkName: "pages/settings/hospitals/_id/index" */))
const _3d99bc60 = () => interopDefault(import('../pages/settings/hospitals/_id/edit.vue' /* webpackChunkName: "pages/settings/hospitals/_id/edit" */))
const _6d1a9d6d = () => interopDefault(import('../pages/embed/rx.vue' /* webpackChunkName: "pages/embed/rx" */))
const _ff352f6e = () => interopDefault(import('../pages/prescriptions/new.vue' /* webpackChunkName: "pages/prescriptions/new" */))
const _123777c4 = () => interopDefault(import('../pages/redirect/new.vue' /* webpackChunkName: "pages/redirect/new" */))
const _7100451e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _4690ad06 = () => interopDefault(import('../pages/paylinks/_slug.vue' /* webpackChunkName: "pages/paylinks/_slug" */))
const _a87ebfc0 = () => interopDefault(import('../pages/prescriptions/_signature.vue' /* webpackChunkName: "pages/prescriptions/_signature" */))
const _b93efcfc = () => interopDefault(import('../pages/new-password/_uid/_token.vue' /* webpackChunkName: "pages/new-password/_uid/_token" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/check-email",
    component: _6c1ff114,
    name: "check-email"
  }, {
    path: "/login",
    component: _ddb0fef0,
    name: "login"
  }, {
    path: "/logout",
    component: _3e4cce0a,
    name: "logout"
  }, {
    path: "/medications",
    component: _38e94a26,
    name: "medications"
  }, {
    path: "/password-updated",
    component: _3600643a,
    name: "password-updated"
  }, {
    path: "/paylinks",
    component: _43236a76,
    name: "paylinks"
  }, {
    path: "/prescriptions",
    component: _2f013e3b,
    name: "prescriptions"
  }, {
    path: "/register",
    component: _fb912958,
    name: "register"
  }, {
    path: "/registered",
    component: _2ec6d173,
    name: "registered"
  }, {
    path: "/resend-confirmation",
    component: _48409666,
    name: "resend-confirmation"
  }, {
    path: "/reset-password",
    component: _6f87c44a,
    name: "reset-password"
  }, {
    path: "/settings",
    component: _07db38d4,
    name: "settings",
    children: [{
      path: "close-account",
      component: _dfdcea46,
      name: "settings-close-account"
    }, {
      path: "export",
      component: _0c62dbff,
      name: "settings-export"
    }, {
      path: "hospitals",
      component: _6b31d701,
      name: "settings-hospitals"
    }, {
      path: "options",
      component: _29babe3a,
      name: "settings-options"
    }, {
      path: "pharmacies",
      component: _301bb4dc,
      name: "settings-pharmacies"
    }, {
      path: "security",
      component: _215278cb,
      name: "settings-security"
    }, {
      path: "user",
      component: _6b1f2156,
      name: "settings-user"
    }, {
      path: "hospitals/new",
      component: _715d0ae2,
      name: "settings-hospitals-new"
    }, {
      path: "hospitals/:id",
      component: _9154ed28,
      name: "settings-hospitals-id"
    }, {
      path: "hospitals/:id/edit",
      component: _3d99bc60,
      name: "settings-hospitals-id-edit"
    }]
  }, {
    path: "/embed/rx",
    component: _6d1a9d6d,
    name: "embed-rx"
  }, {
    path: "/prescriptions/new",
    component: _ff352f6e,
    name: "prescriptions-new"
  }, {
    path: "/redirect/new",
    component: _123777c4,
    name: "redirect-new"
  }, {
    path: "/",
    component: _7100451e,
    name: "index"
  }, {
    path: "/paylinks/:slug",
    component: _4690ad06,
    name: "paylinks-slug"
  }, {
    path: "/prescriptions/:signature",
    component: _a87ebfc0,
    name: "prescriptions-signature"
  }, {
    path: "/new-password/:uid?/:token?",
    component: _b93efcfc,
    name: "new-password-uid-token"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
