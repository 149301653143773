import LRU from 'lru-cache'
import es from 'vuetify/es5/locale/es'
import colors from 'vuetify/es5/util/colors'
import variants from '@/defaults/variants'

const themeCache = new LRU({
  max: 10,
  maxAge: 1000 * 60 * 60, // 1 hour
})

export default ({ $config }) => {
  const variant = variants[$config.whiteLabel]

  return {
    // See: https://vuetifyjs.com/en/customization/theme/#caching
    themeCache,
    // See: https://vuetifyjs.com/en/framework/internationalization
    lang: {
      locales: { es },
      current: 'es',
    },
    // See: https://vuetifyjs.com/en/framework/theme#customizing
    theme: {
      options: {
        // See: https://vuetifyjs.com/en/framework/theme#custom-properties
        customProperties: true,
        // See: https://vuetifyjs.com/en/customization/theme/#variations
        variations: true,
      },
      dark: false,
      themes: {
        light: {
          error: {
            base: colors.red.base,
          },
          warning: {
            base: colors.deepOrange.base,
          },
          info: {
            base: colors.blue.base,
          },
          success: {
            base: colors.green.base,
          },
          white: '#fff',
          black: '#000',
          ...variant.theme.colors.light,
        },
        dark: {
          error: {
            base: colors.red.base,
          },
          warning: {
            base: colors.deepOrange.base,
          },
          info: {
            base: colors.blue.base,
          },
          success: {
            base: colors.green.base,
          },
          white: '#fff',
          black: '#000',
          ...variant.theme.colors.dark,
        },
      },
    },
    icons: {
      iconfont: 'mdiSvg',
    },
  }
}
