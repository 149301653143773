
import {
  ref,
  useFetch,
  useContext,
  defineComponent,
  watch,
} from '@nuxtjs/composition-api'
import capitalize from 'lodash/fp/capitalize'
import yn from 'yn'
import { darkMode } from '~/lib/use-dark-mode'
import { useWindowWidth } from '~/lib/use-window-width'
import breakpoints from '@/lib/breakpoints'

type Nft = {
  total_rx: string
  delta: string
}

export default defineComponent({
  props: {
    mini: {
      type: Boolean,
      default: false,
    },
    responsive: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const context = useContext()

    const delta = ref('')
    const quantity = ref('')

    /**
     * Fetch NFT data from API
     * @returns User's NFT data
     */
    async function fetchNfts() {
      try {
        const { data }: { data: Nft } =
          await context.$axios.get('/api/v2/nfts/')
        quantity.value = data.total_rx
        delta.value = data.delta
      } catch (e) {
        quantity.value = 'N/A'
        delta.value = 'N/A'
      }
    }

    const { fetch } = useFetch(fetchNfts)
    fetch()

    /**
     * Determine if a widget is enabled in the white label
     * @param name - Widget name
     * @returns If the widget is enabled
     */
    function isWidgetEnabled(name: string) {
      // Check if widget is enabled by its white label settings
      const whiteLabelEnabled =
        context.$whiteLabel.options.widgets[name]?.enabled

      // Check if widget is disabled by an environment variable
      const normalizedName = `widgetDisable${name
        .split('-')
        .map((word: string) => capitalize(word))
        .join('')}`
      const disabledByEnvironment = yn(context.$config[normalizedName], {
        default: false,
      })

      // If enabled in the whitelabel and not disabled by environment
      return whiteLabelEnabled && !disabledByEnvironment
    }

    const width = useWindowWidth()
    const mobile = ref(width.value <= breakpoints.sm)
    watch(width, (newWidth) => {
      mobile.value = newWidth <= breakpoints.sm
    })

    return {
      delta,
      quantity,
      darkMode,
      isWidgetEnabled,
      width,
      breakpoints,
      mobile,
    }
  },
})
