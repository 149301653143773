// eslint-disable-next-line import/no-named-as-default
import VuexPersistence from 'vuex-persist'

export default ({ store }) => {
  return new VuexPersistence({
    key: 'persist',
    reducer: (state) => ({
      local: state.local,
      tours: state.tours,
    }),
    storage: window.localStorage,
  }).plugin(store)
}
