import { ref, Ref } from '@nuxtjs/composition-api'

/**
 * Create atom with the width of the window.
 */
const width = ref(0)

export function useWindowWidth(): Ref<number> {
  return width
}
