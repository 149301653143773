
import { defineComponent } from '@nuxtjs/composition-api'
import { useContactSlideOver } from '~/lib/use-contact-slide-over'

export default defineComponent({
  setup() {
    // Contact form state
    const isOpen = useContactSlideOver()

    // Return the reactive variables
    return {
      isOpen,
    }
  },
})
