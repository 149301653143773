
import { mdiClose } from '@mdi/js'

export default {
  data() {
    return {
      show: false,
      message: '',
      color: undefined,
      close: mdiClose,
      actionText: null,
      actionFn: null,
      actionColor: 'primary',
    }
  },
  created() {
    this.$alerts.$on(this.createAlert)
  },
  beforeDestroy() {
    this.$alerts.$off(this.createAlert)
  },
  methods: {
    callback() {
      this.actionFn()
      this.show = false
    },

    createAlert({ message = '', color, ...rest }) {
      if (message !== '') {
        this.show = true
        this.message = message
        this.color = color
        this.actionText = rest.actionText
        this.actionFn = rest.actionFn
        this.actionColor = rest.actionColor
      }
    },
  },
}
