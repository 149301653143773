
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    // SVG path data
    icon: {
      type: String,
      required: true,
    },
    // Any valid CSS color. By default inherits the current context color
    color: {
      type: String,
      required: false,
    },
  },
})
