
import { mdiClose } from '@mdi/js'
import { ref, defineComponent, useRoute, watch } from '@nuxtjs/composition-api'
import MaterialIcon from './MaterialIcon.vue'
import { useNavItems } from '~/lib/use-nav-items'
import whiteLabel from '~/mixins/white-label'
import { useMobileMenu } from '@/lib/use-mobile-menu'
export default defineComponent({
  components: { MaterialIcon },
  mixins: [whiteLabel],

  setup() {
    const route = useRoute()

    // UI Icons
    const icons = { close: mdiClose }

    // Get nav menu items
    const navmenu = useNavItems()

    // Get menu atom and setter
    const menu = useMobileMenu()

    // Close the menu when the route changes
    watch(route, (oldValue, newValue) => {
      const ifRouteChanged = oldValue.name !== newValue.name
      const ifMenuOpen = menu.value === true

      if (ifRouteChanged && ifMenuOpen) {
        // Do in next tick to show click :focus feedback
        menu.value = false
      }
    })

    // Prevent moving the window when swiping
    function blockScroll(event: TouchEvent) {
      if (swiping) {
        event.preventDefault()
        event.returnValue = false
        return false
      }
    }

    // Indicates when the user has touched the screen
    const swiping = ref(false)

    // Save x offset of touch start
    const posXStart = ref(0)

    // Save x offset
    const posXOffset = ref(0)

    // Reset the offset when the menu is closed
    watch(menu, (value) => {
      if (!value) {
        posXOffset.value = 0
      }
    })

    /**
     * Handles the start of a touch gesture
     *
     * @remarks
     * The `touchend` handler is called from `touchstart` instead of binding
     * directly to an element because `touchend` doesn't fire until the user
     * lifts their finger, which may be off the bound element.
     *
     * @param event: A touch event from the browser
     */
    function handleTouchStart(event: TouchEvent) {
      // We only care if one finger is used
      if (event.changedTouches.length !== 1) {
        return
      }

      // User has touched the screen
      swiping.value = true

      // Save the initial touch position
      posXStart.value = event.changedTouches[0].clientX

      // Block scrolling when swiping
      addEventListener('touchmove', blockScroll, { passive: false })

      // Add touchend handler
      addEventListener('touchend', handleTouchEnd, { once: true })
    }

    /**
     * Handles the movement of the touch gesture
     *
     * @param event: A touch event from the browser
     */
    function handleTouchMove(event: TouchEvent) {
      // Get the current touch position offset from the start
      const posX = event.changedTouches[0].clientX - posXStart.value
      if (posX < 0) {
        posXOffset.value = posX
      }
    }

    /**
     * Handles the end of a touch gesture
     *
     * @param event: A touch event from the browser
     */
    function handleTouchEnd(event: TouchEvent) {
      // We only care if one finger is used
      if (event.changedTouches.length !== 1) {
        return
      }

      // User has lifted their finger
      swiping.value = false

      // Get the current touch position
      const posXEnd = event.changedTouches[0].clientX

      // close menu on left swipe
      if (posXStart.value > posXEnd) {
        menu.value = false
      }

      // Stop blocking scrolling
      removeEventListener('touchmove', blockScroll)
    }

    return {
      icons,
      navmenu,
      menu,
      posXOffset,
      swiping,
      handleTouchStart,
      handleTouchMove,
    }
  },
})
