
export default {
  data() {
    return {
      loading: false,
      progress: 0,
    }
  },
  methods: {
    start() {
      this.loading = true
    },
    finish() {
      this.loading = false
    },
    increase(current) {
      this.progress = current
    },
  },
}
