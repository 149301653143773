
import { ValidationProvider } from 'vee-validate-3'
import { computed, defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  components: {
    ValidationProvider,
  },

  props: {
    // The value of the input
    value: {
      type: String,
      default: '',
    },
    // The validation rules
    rules: {
      type: String,
      default: '',
    },
    // The label for the input
    label: {
      type: String,
      required: true,
    },
    // The number of rows for the textarea
    rows: {
      type: Number,
      default: 3,
    },
    // A placeholder for the input
    placeholder: {
      type: String,
      default: '',
    },
  },

  setup(props, { emit }) {
    // Use the label as the name of the input
    const name = computed(() => {
      return props.label.toLowerCase().replace(/\s/g, '-')
    })

    // Emit input event when the value changes
    function onInput(event: Event) {
      const input = event.target as HTMLTextAreaElement
      const value = input.value
      emit('input', value)
    }

    return { name, onInput }
  },
})
