import reduce from 'lodash/fp/reduce'
import first from 'lodash/fp/first'
import { getDefaultClinic, extractClinics } from '@/lib/extract-clinics'

const getDefaultState = () => {
  return {
    hospitals: {},
    clinics: {},
  }
}

export const state = getDefaultState

export const mutations = {
  /** Set hospitals */
  setHospitals(state, hospitals) {
    state.hospitals = Object.assign({}, state.hospitals, hospitals)
  },

  /** Set clinics */
  setClinics(state, clinics) {
    state.clinics = Object.assign({}, state.clinics, clinics)
  },

  /** Clear weather data */
  clearRawData(state) {
    state.rawData = null
  },
  /** Reset state to default */
  reset(state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(state, getDefaultState())
  },
}

/** Get all clinics associated to active hospital */
function getHospitalClinics(clinics, hospital) {
  const defaultClinics = {}
  if (hospital === null) {
    return defaultClinics
  }
  for (const clinic in clinics) {
    if (clinics[clinic].hospitalId === hospital.id) {
      defaultClinics[clinic] = clinics[clinic]
    }
  }
  // The return the default clinics if exists
  return defaultClinics
}

export const actions = {
  async fetch({ commit, rootState }) {
    try {
      const { data } = await this.$axios.get('/api/v2/locations/')

      // Extract hospitals and clinics from response data
      const clinics = extractClinics(data)
      const hospitals = extractHospitals(data)

      // Save hospitals and clinics objects
      commit('setHospitals', hospitals)
      commit('setClinics', clinics)

      if (rootState.rx.clinic) {
        // If the prescription's clinic id has been set, see if it exists in the
        // user's location and if so, set it and it's hospital id
        const clinic = clinics[rootState.rx.clinic]
        if (clinic?.id) {
          commit('rx/setClinic', clinic.id, { root: true })
        }
        if (clinic?.hospitalId) {
          commit('rx/setHospital', clinic.hospitalId, { root: true })
        }
      } else {
        // Use the hospital id if set in the Rx state i.e. from a deeplink
        const hospitalId = rootState.rx.hospital
        if (hospitalId) {
          const hospital = hospitals[hospitalId]
          const hospitalClinics = getHospitalClinics(clinics, hospital)
          const clinic = getDefaultClinic(hospitalClinics)
          commit('rx/setHospital', clinic.hospitalId, { root: true })
          commit('rx/setClinic', clinic.id, { root: true })
        } else {
          // Extract clinicId from user's profile in case they've have a default
          const { clinicId } = rootState.profile

          if (clinicId) {
            // If the user profile value exists, use the clinic and hospital
            const clinic = clinics[clinicId]
            commit('rx/setClinic', clinic.id, { root: true })
            commit('rx/setHospital', clinic.hospitalId, { root: true })
          } else {
            // Otherwise set to the first hospital and clinc in their response data
            const location = first(data)
            const clinic = first(location.clinics)
            commit('rx/setClinic', clinic.id, { root: true })
            commit('rx/setHospital', location.hospital.id, { root: true })
          }
        }
      }
    } catch (err) {
      console.error(err)
      return commit('clearRawData')
    }
  },
}

/**
 * A hospital that can have many clinics
 * @typedef {Object} Hospital
 * @property {string} id - Unique ID
 * @property {string} name - Name of the hospital
 * @property {string} location - Location of the hospital
 * @property {Boolean} default - Whether location is default
 */

/**
 * A clinic that belongs to a hospital
 * @typedef {Object} Clinic
 * @property {string} id - Unique ID
 * @property {string} name - Name of the hospital
 * @property {string} location - Location of the hospital
 */

/**
 * Extracts hospitals from /api/v2/locations/ response
 * @param {Object} locations - Locations API response
 * @returns {Object}
 */
export function extractHospitals(data) {
  return reduce((current, entry) => {
    if (entry.hospital.id) {
      return {
        [entry.hospital.id]: {
          ...entry.hospital,
          default: entry.default,
        },
        ...current,
      }
    }
    return current
  }, {})(data)
}
