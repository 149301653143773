
import { mdiClose } from '@mdi/js'
import {
  defineComponent,
  onMounted,
  onUnmounted,
} from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    // Allow setting the visibility of the slide-over panel via a prop.
    value: {
      type: Boolean,
      default: false,
    },
  },

  setup(_, ctx) {
    // Handle global key press events i.e. close on Escape keypress
    function handleKeyDown(e: KeyboardEvent) {
      if (e.key === 'Escape') {
        closeSlideOver()
      }
    }

    onMounted(() => {
      window.addEventListener('keydown', handleKeyDown)
    })

    onUnmounted(() => {
      window.removeEventListener('keydown', handleKeyDown)
    })

    // Close the value prop when the user clicks outside of it
    function closeSlideOver() {
      ctx.emit('input', false)
    }

    // Icons used in the slide-over panel
    const icons = {
      close: mdiClose,
    }

    return { closeSlideOver, icons }
  },
})
