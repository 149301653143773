const getDefaultState = () => {
  return {
    clinicId: null,
    tourViewed: false,
    historyTourViewed: false,
    feedback: {
      rating: null,
      description: null,
    },
    paylinkRequested: false,
    betaLayout: false,
  }
}

export const state = getDefaultState

export const mutations = {
  /** Set preference in store directly */
  setPreference(state, { key, value }) {
    state[key] = value
  },

  /** Sets the default clinic by id */
  setClinicId(state, value) {
    state.clinicId = value
  },

  /** Indicates the tour has been viewed */
  setTourViewed(state) {
    state.tourViewed = true
  },

  /** Indicates the history tour has been viewed */
  setHistoryTourViewed(state) {
    state.historyTourViewed = true
  },

  /** Set feedback rating and comment */
  setFeedback(state, value) {
    state.feedback = value
  },

  /** Request paylink activation */
  activatePaylink(state) {
    state.paylinkRequested = true
  },

  setDarkMode(state, value) {
    state.darkMode = value
  },

  setBetaLayout(state, value) {
    state.betaLayout = value
  },

  /** Reset state to default */
  reset(state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(state, getDefaultState())
  },
}

export const actions = {
  setPreferences({ commit }, options) {
    if (options?.clinicId) {
      commit('setClinicId', options.clinicId)
    }

    if (options?.tourViewed) {
      commit('setTourViewed')
    }

    if (options?.historyTourViewed) {
      commit('setHistoryTourViewed')
    }

    if (options?.feedback) {
      commit('setFeedback', options.feedback)
    }

    if (options?.paylinkRequested) {
      commit('activatePaylink')
    }

    if (options?.darkMode) {
      commit('setDarkMode', options.darkMode)
    }

    if (options?.betaLayout) {
      commit('setBetaLayout', options.betaLayout)
    }
  },

  /** Update a key with value in ux_settings */
  async updatePreference({ commit }, preference) {
    const { key, value } = preference
    try {
      await this.$axios.patch(`/api/v2/profile/update/`, {
        ux_settings: { [key]: value },
      })
      commit('setPreference', preference)
      return preference
    } catch (err) {
      console.error(`[Update Preference ERROR]: ${err}`)
    }
  },
}

export const getters = {
  /** Whether to show the welcome tour */
  showWelcomeTour(state) {
    return !state.tourViewed
  },

  /** Whether to show the history tour */
  showHistoryTour(state) {
    return !state.historyTourViewed
  },

  /** Whether to show the single tour step once per session */
  showSingleStepTour(state, _, rootState) {
    return rootState.tours.displayTourOnce
  },
}
