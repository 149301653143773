export default {
  data() {
    return {
      ready: false,
      mediaQuery: null,
    }
  },

  computed: {
    supportsDarkMode() {
      return Boolean(this.$whiteLabel.options.theme.colors.dark)
    },
  },

  mounted() {
    // If the theme supports dark mode
    if (this.supportsDarkMode) {
      // Get system color scheme from a media query
      const systemDarkModeEnabled = window.matchMedia(
        '(prefers-color-scheme: dark)',
      ).matches

      // Set color scheme
      this.$vuetify.theme.dark = this.getDarkModeValue(systemDarkModeEnabled)

      // Add listener to toggle dark mode based on user's OS setting
      this.mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
      this.mediaQuery.addEventListener('change', this.watchDarkMode)
    }

    // Theme selected and ready semaphore
    this.ready = true
  },

  beforeDestroy() {
    // Remove event listener from media query, if it exists
    if (this.mediaQuery?.removeEventListener) {
      this.mediaQuery.removeEventListener('change', this.watchDarkMode)
    }
  },

  methods: {
    /**
     * Get dark mode boolean based on user options
     * @param {boolean} systemDarkModeEnabled - Media query for system dark mode value
     * @returns {Boolean}
     */
    getDarkModeValue(systemDarkModeEnabled) {
      if (this.$auth.$state?.loggedIn) {
        // If the user is logged in, respect their setting
        const options = this.$auth?.user?.ux_settings
        // If user has auto setting enabled, use system setting
        if (options.darkMode === 'auto') {
          return systemDarkModeEnabled
        }
        // Otherwise use the user's preference
        return options.darkMode
      } else {
        // Otherwise use the system color scheme
        return systemDarkModeEnabled
      }
    },

    /** Change dark mode based on system changes */
    watchDarkMode(e) {
      this.$vuetify.theme.dark = this.getDarkModeValue(e.matches)
    },
  },
}
