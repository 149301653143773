
import { computed, defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    // The button type
    type: {
      validator: (value: string) => {
        return ['button', 'submit', 'reset'].indexOf(value) !== -1
      },
      default: 'button',
    },
    // Icon data for SVG
    icon: {
      type: String,
      required: false,
    },
    // Size of button
    size: {
      // The value must match one of these strings
      validator: (value: string) => {
        return ['small', 'medium', 'large', 'x-large'].indexOf(value) !== -1
      },
      default: 'medium',
    },
    // Color of button
    color: {
      // The value must match one of these strings
      validator: (value: string) => {
        return (
          [
            'primary',
            'secondary',
            'orange',
            'red',
            'green',
            'purple',
            'yellow',
            'blue',
            'indigo',
            'white',
          ].indexOf(value) !== -1
        )
      },
      default: 'primary',
    },
    // The button is disabled
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const { size, color } = props

    const colors =
      color === 'white'
        ? 'tw-text-gray-700 dark:tw-text-gray-100 tw-bg-white dark:tw-bg-gray-900 hover:tw-bg-gray-50 tw-border-gray-300 dark:tw-border-gray-700'
        : `tw-text-white tw-bg-${color}-500 hover:tw-bg-${color}-600 tw-border-transparent`

    const disabled =
      color === 'white'
        ? 'tw-text-gray-700 dark:tw-text-gray-100 tw-bg-gray-50 dark:tw-bg-gray-900 hover:tw-bg-gray-50 tw-border-gray-300 dark:tw-border-gray-700'
        : `tw-text-white tw-bg-${color}-500 hover:tw-bg-${color}-500 tw-border-transparent`

    const classes = computed(() => ({
      'tw-py-2 tw-px-2 tw-leading-4 tw-text-sm': size === 'small',
      'tw-py-2 tw-px-4 tw-text-sm': size === 'medium',
      'tw-py-2 tw-px-4 tw-text-base': size === 'large',
      'tw-py-3 tw-px-6 tw-text-base': size === 'x-large',
      [colors]: !props.disabled,
      [disabled]: props.disabled,
      'tw-cursor-not-allowed tw-bg-opacity-50': props.disabled,
    }))

    return { classes }
  },
})
