
import { useContactSlideOver } from '@/lib/use-contact-slide-over'
import { ValidationObserver } from 'vee-validate-3'
import { mdiClose } from '@mdi/js'
import {
  defineComponent,
  ref,
  useContext,
  useStore,
  watch,
} from '@nuxtjs/composition-api'

export default defineComponent({
  components: { ValidationObserver },

  props: {
    close: {
      type: Function,
      required: true,
    },
  },

  setup() {
    // Contact form state
    const isOpen = useContactSlideOver()

    // Validation ref
    const observer = ref<InstanceType<typeof ValidationObserver>>()

    // Form ref
    const form = ref<HTMLFormElement>()

    // Icons used in the template
    const icons = { close: mdiClose }

    // Form data
    const subject = ref('')
    const message = ref('')

    // Watch for changes to the isOpen variable
    watch(isOpen, (newValue) => {
      // If the slide-over is closed reset the form and values
      if (!newValue) {
        // Reset the form values
        subject.value = ''
        message.value = ''

        // Reset the form
        form.value?.reset()

        // Reset the form validation when the slide-over is closed
        observer.value?.reset()
      }
    })

    // Submit handler
    const store = useStore()
    const context = useContext()

    function onSubmit() {
      const rx = JSON.stringify((store.state as any).rx)
      const profile = JSON.stringify((store.state as any).profile)
      const payload = {
        subject: subject.value,
        message: message.value,
        metadata: `[METADATA PRX2] Profile: ${profile}, RX: ${rx}`,
      }

      try {
        context.$axios.$post('/api/v2/contact/', payload)
        context.$alerts.success(
          'Gracias por sus comentarios. Nos pondremos en contacto con usted lo antes posible.'
        )
        isOpen.value = false
      } catch (error) {
        console.log(error)
        // Notify user of error
        context.$alerts.error(
          'Hubo algún error inesperado al intentar cambiar el rol.'
        )
      }
    }

    // Return the reactive variables
    return {
      observer,
      isOpen,
      icons,
      subject,
      message,
      onSubmit,
      form,
    }
  },
})
