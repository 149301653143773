const getDefaultState = () => {
  return {
    lastMonth: null,
    topMedications: null,
  }
}

export const state = getDefaultState

export const mutations = {
  /** Set weather from response value */
  setLastMonth(state, value = null) {
    state.lastMonth = value
  },

  /** Clear weather data */
  clearLastMonth(state) {
    state.lastMonth = null
  },

  /** Set top medications from response value */
  setTopMedications(state, value = null) {
    state.topMedications = value
  },

  /** Clear top medications data */
  clearTopMedications(state) {
    state.topMedications = null
  },
  /** Reset state to default */
  reset(state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(state, getDefaultState())
  },
}

export const actions = {
  async fetchLastMonth({ commit }) {
    try {
      const { data } = await this.$axios.get('/api/v2/figures/last_month/')
      if (Object.prototype.hasOwnProperty.call(data, '401')) {
        throw data
      }
      return commit('setLastMonth', data)
    } catch (err) {
      console.error(err)
      return commit('clearLastMonth')
    }
  },

  async fetchTopMedications({ commit }) {
    try {
      const { data } = await this.$axios.get('/api/v2/figures/top_5_meds/')
      return commit('setTopMedications', data)
    } catch (err) {
      console.error(err)
      return commit('clearTopMedications')
    }
  },
}
