const getDefaultState = () => {
  return {
    rawData: null,
  }
}

export const state = getDefaultState

export const mutations = {
  /** Set weather from response value */
  setRawData(state, value = null) {
    state.rawData = value
  },

  /** Clear weather data */
  clearRawData(state) {
    state.rawData = null
  },

  /** Reset state to default */
  reset(state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(state, getDefaultState())
  },
}

export const actions = {
  async fetchRawData({ commit }, search = 'Ciudad de México') {
    const params = { search }
    try {
      const { data } = await this.$local.get('/api/v2/weather', { params })
      return commit('setRawData', data)
    } catch (err) {
      console.error(err)
      return commit('clearRawData')
    }
  },
}
