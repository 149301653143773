import yn from 'yn'

export default ({ app }) => {
  // Every time the route changes (fired on initialization too)
  app.router.afterEach((to, from) => {
    // If the route we came from has the embedded property
    if (yn(from.query.embed, { default: false })) {
      // Add the embed property to the current route
      to.query.embed = 1
    }
  })
}
