/**
 * Redirect to add hospital if logged in and hospital data is missing
 * Redirect to user settings if logged in and data is not filled
 */
export default function ({ $auth, redirect }) {
  // The user must be logged in
  if ($auth.$state?.loggedIn === true) {
    // The user must not have data
    if ($auth.user?.has_fillup_data === false) {
      return redirect('/settings/user')
    }
    // The user must not have hospitals
    if ($auth.user?.has_hospital_data === false) {
      return redirect('/settings/hospitals/new')
    }
    // The user must have a verified specialty
    if ($auth.user?.specialty_verified === false) {
      return redirect('/prescriptions')
    }
  }
}
