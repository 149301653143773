import compact from 'lodash/compact'
import { computed, useContext, useStore } from '@nuxtjs/composition-api'
import { derivePaylinksStateFromContext } from '~/lib/derive-paylink-state'

import {
  mdiViewDashboard,
  mdiHistory,
  mdiCog,
  mdiAccountQuestion,
  mdiLogout,
  mdiMedicalBag,
  mdiCreditCardOutline,
} from '@mdi/js'
import { Auth } from '@nuxtjs/auth'
import { v4 as uuidv4 } from 'uuid'
import { UserProfile } from './auth-types'
import { useContactSlideOver } from './use-contact-slide-over'

interface ScopedState {
  auth: Auth<UserProfile>
}

interface NavMenuLink {
  title: string
  icon: string
  id: string
}

interface NavMenuSpacer {
  spacer: boolean
  id: string
}

export type NavMenuItem = NavMenuLink | NavMenuSpacer

/**
 * Generates the list of items based on paylink status
 * @param paylinks - Whether to show paylinks option
 * @param helpCallback - Callback to show help
 */
export function generateList(
  paylinks: boolean,
  helpCallback: () => void
): NavMenuItem[] {
  return compact([
    {
      title: 'Dashboard',
      icon: mdiViewDashboard,
      to: { name: 'index' },
      id: uuidv4(),
      tag: 'dashboard',
    },
    {
      title: 'Historial',
      icon: mdiHistory,
      to: { name: 'prescriptions' },
      id: uuidv4(),
      tag: 'history',
    },
    {
      title: 'Medicamentos',
      icon: mdiMedicalBag,
      to: { name: 'medications' },
      id: uuidv4(),
      tag: 'medications',
    },
    paylinks && {
      title: 'Paylinks',
      icon: mdiCreditCardOutline,
      to: { name: 'paylinks' },
      id: uuidv4(),
      tag: 'paylinks',
    },
    {
      spacer: true,
      id: uuidv4(),
    },
    {
      title: 'Configuración',
      icon: mdiCog,
      to: { name: 'settings-user' },
      id: uuidv4(),
      tag: 'settings',
    },
    {
      title: 'Ayuda',
      icon: mdiAccountQuestion,
      click: helpCallback,
      id: uuidv4(),
      tag: 'help',
    },
    {
      spacer: true,
      id: uuidv4(),
    },
    {
      title: 'Cerrar Sesión',
      icon: mdiLogout,
      to: { name: 'logout' },
      id: uuidv4(),
      tag: 'logout',
    },
  ])
}

/**
 * Hook for sharing nav menu items across components
 * @returns A list of computed nav menu items
 */
export const useNavItems = () => {
  const context = useContext()
  const store = useStore()

  // Get paylinks status from context
  const paylinksEnabled = derivePaylinksStateFromContext(context)
  const paylinksActive = (store.state as ScopedState).auth.user
    ?.activate_paymentlinks
  const paylinks = Boolean(paylinksEnabled && paylinksActive)

  // Get contact slideover handler
  const isOpen = useContactSlideOver()

  // Add function to open slideover
  function openSlideOver() {
    isOpen.value = true
  }

  return computed(() => generateList(paylinks, openSlideOver))
}
