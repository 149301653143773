
import yn from 'yn'
import { mdiMenuLeft, mdiMenuRight, mdiMenu } from '@mdi/js'
import Alert from '@/components/alert'
import whiteLabel from '@/mixins/white-label'

export default {
  components: { Alert },

  mixins: [whiteLabel],

  middleware({ res, req, store }) {
    if (process.server) {
      // Get viewport width from client-hints header, else default to mobile
      const width = req.headers['viewport-width'] || 600
      store.dispatch('ui/setDefaultsForWidth', width)
    }
  },

  data() {
    return {
      // Window width for nav drawer calculation
      width: 0,
      // Current year
      year: new Date().getFullYear(),
      // Individual icon imports
      icon: {
        right: mdiMenuRight,
        left: mdiMenuLeft,
        menu: mdiMenu,
      },
    }
  },

  head() {
    return {
      title: this.$whiteLabel.options.meta.name,
      titleTemplate: `%s - ${this.$whiteLabel.options.meta.name}`,
    }
  },

  computed: {
    // Whether to show the embedded view or not
    embedded() {
      return yn(this.$route.query.embed, { default: false })
    },
    // Hide drawer by default, as it's not rendered in SSR
    drawer: {
      get() {
        return this.$store.state.ui.drawer
      },
      set(value) {
        this.$store.commit('ui/setDrawer', value)
      },
    },
    // Whether to always show the drawer
    permanent: {
      get() {
        return this.$store.state.ui.permanent
      },
      set(value) {
        this.$store.commit('ui/setPermanent', value)
      },
    },
    // Mini sidebar by default
    mini: {
      get() {
        return this.$store.state.ui.mini
      },
      set(value) {
        this.$store.commit('ui/setMini', value)
      },
    },
    // Whether to render sidebar on right side
    right: {
      get() {
        return this.$store.state.ui.right
      },
      set(value) {
        this.$store.commit('ui/setRight', value)
      },
    },
    // Whether to render the app bar
    navbar: {
      get() {
        return this.$store.state.ui.navbar
      },
      set(value) {
        this.$store.commit('ui/setNavbar', value)
      },
    },
  },

  watch: {
    // When width changes, update navigation bar
    width(newWidth, oldWidth) {
      if (newWidth !== oldWidth) {
        this.$store.dispatch('ui/setDefaultsForWidth', newWidth)
      }
    },
  },

  created() {
    // Preload user profile data
    const options = this.$auth?.user?.ux_settings
    if (options) {
      this.$store.dispatch('profile/setPreferences', options)
    }

    // If dark mode is set and different from theme, update with new value
    const hasDarkModeInProfile = options?.darkMode
    const currentModeDifferent = this.$vuetify.theme.dark !== options?.darkMode
    const whiteLabelSupport = this.$whiteLabel.options.theme.colors.dark
    if (hasDarkModeInProfile && currentModeDifferent && whiteLabelSupport) {
      this.$vuetify.theme.dark = options.darkMode
    }
  },

  mounted() {
    // Force calculation of width on mount
    this.updateWidth()
  },

  methods: {
    /** Toggles dark mode on and off, saving status to profile */
    async toggleDarkMode() {
      const newValue = !this.$vuetify.theme.dark
      this.$vuetify.theme.dark = newValue
      await this.$store.dispatch('profile/updatePreference', {
        key: 'darkMode',
        value: newValue,
      })
    },

    /** Updates navigation drawer settings */
    updateWidth() {
      if (this.embedded) {
        this.width = window.innerWidth
      } else {
        this.width = window.outerWidth
      }
    },

    /** Do nothing */
    noop() {},

    /** Toggle drawer or mini based on context */
    toggleMiniOrDrawer() {
      if (this.right) {
        this.drawer = !this.drawer
      } else {
        this.mini = !this.mini
      }
    },
  },
}
