
import {
  computed,
  defineComponent,
  PropType,
  useRoute,
} from '@nuxtjs/composition-api'
import { useMiniMenu } from '~/lib/use-mini-menu'
import { useResponsiveMenu } from '~/lib/use-responsive-menu'

interface NavMenuLink {
  to: string
  title: string
  icon: string
}

interface NavMenuSpacer {
  spacer: boolean
}

type NavMenuItem = NavMenuLink | NavMenuSpacer

export default defineComponent({
  setup() {
    // Get current route object
    const route = useRoute()

    // Reactive mini sidebar atom
    const mini = useMiniMenu()

    // Whether to use the responsive menu or not
    const responsive = useResponsiveMenu(mini)

    // Enable mini mode when responsive mode is disabled
    const isMini = computed(
      () => mini.value === true && responsive.value === false
    )

    return { route, mini, isMini, responsive }
  },

  props: {
    // Items to show in sidebar
    items: Array as PropType<NavMenuItem[]>,
  },
})
