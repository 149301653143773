import reduce from 'lodash/fp/reduce'

const getDefaultState = () => {
  return {
    items: {},
  }
}

export const state = getDefaultState

export const mutations = {
  /** Set items from API response */
  setItems(state, value = []) {
    state.items = reduce((items, item) => {
      items[item.uid] = item
      return items
    }, state.items)(value)
  },

  /** Set item in store directly */
  setItem(state, value) {
    state.items[value.uid] = value
  },

  /** Reset state to default */
  reset(state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(state, getDefaultState())
  },
}

export const actions = {
  async fetchById({ commit }, uid) {
    try {
      if (!uid) {
        throw new Error("fetchPatientById requires the user's unique id ")
      }
      const response = await this.$axios.get(`/api/v2/patient/${uid}/`)
      return commit('setItems', [response.data])
    } catch (err) {
      console.error(err)
    }
  },
}
