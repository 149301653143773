
import { mdiMenu } from '@mdi/js'
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  useRoute,
  useStore,
  watch,
} from '@nuxtjs/composition-api'
import debounce from 'lodash/debounce'
import yn from 'yn'
import { useMobileMenu } from '@/lib/use-mobile-menu'
import whiteLabel from '@/mixins/white-label'
import breakpoints from '@/lib/breakpoints'
import { useMiniMenu } from '~/lib/use-mini-menu'
import { useResponsiveMenu } from '~/lib/use-responsive-menu'
import { useWindowWidth } from '~/lib/use-window-width'

export default defineComponent({
  mixins: [whiteLabel],

  props: {
    darkMode: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const store = useStore()

    // UI icons
    const icons = {
      menu: mdiMenu,
    }

    // Get UI atoms
    const mini = useMiniMenu()
    const responsive = useResponsiveMenu(mini)
    const mobile = useMobileMenu()

    const width = useWindowWidth()
    watch(width, (newWidth, oldWidth) => {
      // Hide mobile menu anytime window is resized
      mobile.value = false

      if (newWidth !== oldWidth) {
        // Set width in store for components that depend on it
        store.dispatch('ui/setDefaultsForWidth', newWidth)

        // Re-enable responsive mode
        responsive.value = true

        // sm
        if (newWidth <= breakpoints.sm) {
          mini.value = false
        }

        // md
        if (newWidth > breakpoints.sm && newWidth <= breakpoints.md) {
          mini.value = true
        }

        // lg
        if (newWidth > breakpoints.md && newWidth <= breakpoints.lg) {
          mini.value = true
        }

        // xl
        if (newWidth > breakpoints.lg && newWidth <= breakpoints.xl) {
          mini.value = false
        }
      }
    })

    // Determine whether UI is embedded to properly set width
    const route = useRoute()
    const embedded = computed(() =>
      yn(route.value.query.embed, { default: false }),
    )
    function setWidth() {
      if (embedded) {
        width.value = window.innerWidth
      } else {
        width.value = window.outerWidth
      }
    }

    const debouncedSetWidth = debounce(setWidth, 100, { leading: true })

    onMounted(function handleOnMount() {
      // Run on mount to set the initial state
      debouncedSetWidth()

      // Listen for orientation change
      window.addEventListener('resize', debouncedSetWidth)
    })

    // Remove event listener when unmounting the component
    onUnmounted(function handleOnUnmount() {
      window.removeEventListener('change', debouncedSetWidth)
    })

    return { icons, mobile, embedded }
  },
})
