export const state = () => ({
  // Make vuetify thresholds available to VueX
  thresholds: {},
})

export const mutations = {
  // Sets thresholds in rootState
  setVuetifyThresholds(state, thresholds) {
    state.thresholds = { ...thresholds }
  },
}

export const actions = {
  // Nuxt action used when we have some data on the server we want to give
  // directly to the client-side
  // https://nuxtjs.org/docs/2.x/directory-structure/store/#the-nuxtserverinit-action
  nuxtServerInit({ commit }, context) {
    // Save thresholds in global state
    commit('setVuetifyThresholds', context.$vuetify.breakpoint.thresholds)
  },
  // Reset all modules with user information
  reset({ commit }) {
    commit('favorites/reset', null, { root: true })
    commit('graphs/reset', null, { root: true })
    commit('locations/reset', null, { root: true })
    commit('patient/reset', null, { root: true })
    commit('paylink/reset', null, { root: true })
    commit('prescription/reset', null, { root: true })
    commit('profile/reset', null, { root: true })
    commit('rx/reset', null, { root: true })
    commit('tours/reset', null, { root: true })
    commit('weather/reset', null, { root: true })
  },
}
