import { Module, Mutation, VuexModule } from 'vuex-module-decorators'

export type ColorScheme = 'dark' | 'light' | 'no-preference'

@Module
export default class ClientHints extends VuexModule {
  viewportWidth: number = 0
  prefersColorScheme: ColorScheme = 'no-preference'

  @Mutation
  setViewportWidth(width: number) {
    this.viewportWidth = width
  }

  @Mutation
  setPreferredColorScheme(scheme: ColorScheme) {
    this.prefersColorScheme = scheme
  }
}
