
import {
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  useContext,
  // useMeta,
  useStore
} from '@nuxtjs/composition-api'
import { darkMode } from '~/lib/use-dark-mode'

export default defineComponent({
  // Save client hints in Vuex store
  middleware({ req, res, store }) {
    res?.setHeader('Accept-CH', 'Viewport-Width, Sec-CH-Prefers-Color-Scheme')

    if (req?.headers) {
      if (req.headers['viewport-width']) {
        store.commit(
          'clientHints/setViewportWidth',
          req.headers['viewport-width']
        )
      }

      if (req.headers['sec-ch-prefers-color-scheme']) {
        store.commit(
          'clientHints/setPreferredColorScheme',
          req.headers['sec-ch-prefers-color-scheme']
        )
      }
    }
  },

  setup () {
    const context = useContext()
    const store = useStore()

    // Add ref to track color theme Media Query
    const mediaQuery = ref<MediaQueryList>()

    // TODO: Nuxt-Bridge - Fix i18n meta with no root
    // Add Nuxt i18n attributes to meta
    // const i18nHead = root.$nuxtI18nHead()
    // useMeta({
    //   htmlAttrs: { ...i18nHead.htmlAttrs },
    //   meta: [...i18nHead.meta],
    //   link: [...i18nHead.link],
    //   title: context.$whiteLabel.options.meta.name,
    //   titleTemplate: `%s - ${context.$whiteLabel.options.meta.name}`,
    // })

    // Preload user profile data
    const options = context.$auth?.user?.ux_settings
    if (options) {
      store.dispatch('profile/setPreferences', options)
    }

    // Whether the white label supports dark mode
    const whiteLabelSupport = context.$whiteLabel.options.theme.colors.dark

    // Dark mode value from Client Hints header
    const clientHintsDarkMode =
      (store.state as any).clientHints.prefersColorScheme === 'dark'

    // Get the initial dark mode value using client hints
    const initialValue = whiteLabelSupport
      ? getDarkModeValue(clientHintsDarkMode)
      : false

    // Initialize reactive dark mode variable with client hint
    darkMode.value = initialValue

    // Initialize Vuetify with dark mode value from client hints
    context.$vuetify.theme.dark = darkMode.value

    onMounted(() => {
      if (whiteLabelSupport) {
        // Add listener to toggle dark mode if it changes in the browser
        mediaQuery.value = window.matchMedia('(prefers-color-scheme: dark)')
        mediaQuery.value.addEventListener('change', watchDarkMode)

        // Read dark mode value from browser i.e. missing from client hints
        const systemDarkModeEnabled = window.matchMedia(
          '(prefers-color-scheme: dark)'
        ).matches

        // Update dark mode value using browser
        darkMode.value = getDarkModeValue(systemDarkModeEnabled)

        // Update Vuetify with dark mode value from browser
        context.$vuetify.theme.dark = darkMode.value
      }

      onUnmounted(() => {
        // Remove event listener, if set
        mediaQuery.value?.removeEventListener('change', watchDarkMode)
      })
    })

    /**
     * Get dark mode boolean based on user options
     * @param systemDarkModeEnabled - Media query for system dark mode value
     * @returns Dark mode value
     */
    function getDarkModeValue (systemDarkModeEnabled: boolean): boolean {
      if (context.$auth.$state?.loggedIn) {
        // If the user is logged in, respect their setting
        const options = context.$auth?.user?.ux_settings
        // If user has auto setting enabled, use system setting
        if (options.darkMode === 'auto') {
          return systemDarkModeEnabled
        }
        // Otherwise use the user's preference
        return options.darkMode
      } else {
        // Otherwise use the system color scheme
        return systemDarkModeEnabled
      }
    }

    /** Change dark mode based on system changes */
    function watchDarkMode ({ matches }: MediaQueryListEvent) {
      darkMode.value = getDarkModeValue(matches)
      context.$vuetify.theme.dark = darkMode.value
    }

    return { darkMode }
  },
  head: {}
})
